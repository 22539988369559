@use 'sass:math';

// Sarabun font
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@mixin grid-center-last($columns) {
    $total_columns: $columns * 2;
    $span: math.div($total-columns, $columns);

    $offset: $columns - 1;
    $offset-over: $columns;

    $nth-child: if($columns % 2==0, "odd", "even");
    $nth-child-over: if($columns % 2==1, "odd", "even");

    display: grid;
    grid-template-columns: repeat($total_columns, 1fr);

    &>* {
        grid-column: span $span;

        &:nth-last-child(#{$offset}):nth-child(#{$nth-child}) {
            grid-column: $span / span $span;
        }

        &:nth-last-child(#{$offset-over}):nth-child(#{$nth-child-over}) {
            grid-column: span $span;
        }
    }
}

$app-primary: #201E1E;
$app-primary-alt: #EB6C18;
$app-secondary: #FFDD21;
$app-dark: #373435;
$app-muted: #8B8B8B;

body {
    font-family: "Sarabun", sans-serif;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

footer {
    margin-top: auto;
}

#main-menu {
    li {
        transition: background-color ease 500ms;
    }

    li.active {
        border-bottom: solid 2px $app-secondary;
    }

    li:hover {
        background: $app-secondary;
        transition: all ease 500ms;
    }
}

.hover-dark {
    transition: all ease 200ms;
}

.hover-dark:hover {
    background-color: $app-dark !important;

    * {
        color: white !important;
        transition: all ease 200ms;
    }
}

.square-input,
.square-btn {
    border-radius: 0;
    padding: .75rem 1.5rem;
}

.square-input:focus {
    outline: none;
    box-shadow: none;
}

.black-input {
    background: $app-dark;
    color: white;
}

.black-input::placeholder {
    color: white;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: white;
}

.rounded-left {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
}

.border-r-0 {
    border-right: 0;
}

.mb-rec-1 {
    * {
        margin-bottom: 0.25rem !important;
    }
}

// Sobrescreve Bootstrap
.bg-primary,
.btn-primary {
    background-color: $app-primary !important;
}

.bg-primary-alt,
.btn-primary-alt {
    background-color: $app-primary-alt !important;
}

.btn-primary,
.border-primary {
    border-color: $app-primary !important;
}

.text-primary {
    color: $app-primary !important;
}

.bg-secondary,
.btn-secondary {
    background-color: $app-secondary !important;
}

.btn-secondary,
.border-secondary {
    border-color: $app-secondary !important;
}

.text-secondary {
    color: $app-secondary !important;
}

.bg-dark,
.btn-dark {
    background-color: $app-dark !important;
}

.btn-dark,
.border-dark {
    border-color: $app-dark !important;
}

.text-dark {
    color: $app-dark !important;
}

.bg-muted,
.btn-muted {
    background-color: $app-muted !important;
}

.btn-muted,
.border-muted {
    border-color: $app-muted !important;
}

.text-muted {
    color: $app-muted !important;
}

// Utilities
.px-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
}

.px-20 {
    padding-right: 12rem !important;
    padding-left: 12rem !important;
}

.mx-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
}

.mx-20 {
    margin-right: 12rem !important;
    margin-left: 12rem !important;
}

.mt-10 {
    margin-top: 6rem !important;
}

.my-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
}

.word-spacing-5px {
    word-spacing: 5px !important;
}

.font-size-2 {
    font-size: 2rem !important;
}

.font-size-1 {
    font-size: 1rem !important;
}

.font-size-1-25 {
    font-size: 1.25rem !important;
}

.font-size-1-5 {
    font-size: 1.5rem !important;
}

.font-size-1-75 {
    font-size: 1.75rem !important;
}

.max-width-150px {
    max-width: 150px !important;
}

.max-width-200px {
    max-width: 200px !important;
}

.max-width-250px {
    max-width: 250px !important;
}

.max-width-350px {
    max-width: 350px !important;
}

.min-width-max-content {
    min-width: max-content !important;
}

.min-width-350px {
    min-width: 350px !important;
}

.max-width-500px {
    max-width: 500px !important;
}

.max-width-750px {
    max-width: 750px !important;
}

.min-width-500px {
    min-width: 500px !important;
}

.width-150px {
    width: 150px !important;
}

.height-150px {
    height: 150px !important;
}

.height-300px {
    height: 300px !important;
}

.height-400px {
    height: 400px !important;
}

.height-500px {
    height: 500px !important;
}

.max-height-100px {
    max-height: 100px !important;
}

.max-height-150px {
    max-height: 150px !important;
}

.max-height-300px {
    max-height: 300px !important;
}

.width-max-content {
    width: max-content !important;
}

.object-fit-cover {
    object-fit: cover !important;
}

.hover-opacity-75:hover {
    opacity: 0.75;
}

.opacity-75 {
    opacity: 0.75;
}

.opaque-black-75 {
    position: relative;
}

.opaque-black-75:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.75;
}

.opaque-primary-25 {
    position: relative;
}

.opaque-primary-25:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $app-primary;
    opacity: 0.25;
}

.z-index-1 {
    z-index: 1;
}

.position-absolute.center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.position-absolute.center-x {
    left: 50%;
    transform: translateX(-50%);
}

.gapx-4 {
    column-gap: 1.5rem !important;
}

.grid-template-columns-2 {
    grid-template-columns: repeat(2, 1fr);
}

.grid-template-columns-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-template-columns-3.center-last {
    @include grid-center-last(3);
}

.divider-primary {
    background-color: $app-primary;
    height: 2px !important;
    opacity: 1;
}

.hidden-container {
    height: 0px;
    overflow-y: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

// GENERAL
a {
    text-decoration: none;
    color: inherit;
}

ul,
ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

.map {
    height: 400px;
    width: 100%;
}

iframe {
    width: 100% !important;
    border-radius: .5rem;
}

// Large
@media only screen and (max-width: 1200px) {
    html {
        font-size: 13px;
    }
}

// Medium
@media only screen and (max-width: 992px) {
    html {
        font-size: 11px;
    }

    .max-width-md-125px {
        max-width: 125px !important;
    }

    .max-width-md-300px {
        max-width: 300px !important;
    }

    .min-width-md-300px {
        min-width: 300px !important;
    }
}

// Small
@media only screen and (max-width: 768px) {
    html {
        font-size: 10px;
    }

    .text-small-center {
        text-align: center !important;
    }

    .max-width-sm-150px {
        max-width: 150px !important;
    }

    .max-height-sm-400px {
        max-height: 400px !important;
    }

    .mx-sm-auto {
        margin: auto !important;
    }

    .p-small-0 {
        padding: 0 !important;
    }

    .mb-small-5 {
        margin-bottom: 3rem !important;
    }

    .mb-small-2 {
        margin-bottom: 0.5rem !important;
    }

    .mb-small-3 {
        margin-bottom: 1rem !important;
    }

    .px-small-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .px-small-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .height-small-unset {
        height: unset !important;
    }

    .align-items-small-center {
        align-items: center !important;
    }

    .justify-content-small-center {
        justify-content: center !important;
    }
}

// X-Small
@media only screen and (max-width: 576px) {
    html {
        font-size: 12px;
    }

    .rounded-xs-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .rounded-xs-top {
        border-top-left-radius: 0.375rem !important;
        border-top-right-radius: 0.375rem !important;
    }

    .d-xs-none {
        display: none !important;
    }

    .d-xs-block {
        display: block !important;
    }

    .d-xs-flex {
        display: flex !important;
    }

    .flex-xs-column {
        flex-direction: column;
    }

    .justify-content-xs-start {
        justify-content: start !important;
    }

    .pe-xs-0 {
        padding-right: 0 !important;
    }

    .p-xs-0 {
        padding: 0 !important;
    }

    .px-xs-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }

    .py-xs-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .text-xs-center {
        text-align: center !important;
    }

    .mx-xs-auto {
        margin: auto !important;
    }

    .my-xs-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }

    .my-xs-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    .mx-xs-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }

    .mt-xs-0 {
        margin-top: 0 !important;
    }

    .mt-xs-5 {
        margin-top: 3rem !important;
    }

    .height-xs-unset {
        height: unset !important;
    }

    .height-xs-300px {
        height: 300px !important;
    }

    .max-width-xs-300px {
        max-width: 300px !important;
    }

    .max-width-xs-unset {
        max-width: unset !important;
    }

    .grid-template-columns-xs-1 {
        grid-template-columns: repeat(1, 1fr);
    }

    .grid-template-columns-xs-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    .grid-template-columns-xs-2.center-last {
        @include grid-center-last(2);
    }

    #main-menu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        transform: translateX(-100%);

        .content {
            flex-direction: column;
            background: white;
            height: 100vh;
            justify-content: flex-start !important;
            width: 60vw;

            img {
                margin: 15px auto;
                max-width: 90% !important;
            }

            ul {
                background-color: $app-secondary;
                flex-direction: column;
                flex: 1;
                padding-top: 10px !important;
                margin: 0;

                li {
                    font-size: 16px;
                    color: $app-dark !important;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }

                li.active {
                    color: white !important;
                }
            }
        }
    }
}